<template>
<div class="res-box" :class="{'phone-res-box-wrapper':isPhone}">
    <div class='result-box'
        :class="{'phone-res-box':isPhone}"
        v-loading="loading2"
        element-loading-text="文件解析中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        >
        <!-- 文档 -->
        <iframe v-if='iframeUrl != null' id="frame1" @load='iframeLoad' :src="iframeUrl" width='100%' :class="{'fixedHeight':fixedHeightStatus,'relativeHeight':!fixedHeightStatus}" frameborder="0" allowfullscreen="true"></iframe>
        <!-- 图片 -->
        <img v-if='imgUrl !=null' class='previewImg' :src="imgUrl" @error="imgLoadError()" alt="" v-show='!noUrl'/>
        <!-- 视频 -->
        <!-- wyp 更改自定义视频模块 2021/10/13 -->
        <video-templete v-if='videoUrl!=null && deviceIsPc' :playerOption="playerOptions"></video-templete>
        <video class="preview-video" id="previewVideo" v-if='videoUrl !=null && !deviceIsPc' width='100%' height='100%' controls="controls" :src="videoUrl" autoplay controlsList="nodownload"></video>
        <!-- 音频 -->
        <audio v-if='audioUrl !=null' id="previewAudio" controls="controls" :src="audioUrl" controlsList="nodownload" :class="{'phone-res-audio':isPhone}"></audio>
        <div v-if='yoyaVideo !=null' id='result-box'></div>
        <div v-if='noSupport == true' class='no-support' v-show='!noUrl'>
            <slot name='resNotSupport'>
                <img src="/static/images/res/noSup.png" class="noSup">
                <div v-if='isLogin && is271 && loadAccount().role == "学生"' class="dl-info">
                    当前资源不支持在线预览
                </div>
                <div v-else class="dl-info">当前资源不支持在线预览
                    <span v-if="getSunResType(info)">
                        ，请<a href='javascript:;' @click='getDownload' class="dl-iflink">下载</a>后查看
                    </span>
                </div>
            </slot>
        </div>
        <div v-show='noUrl' style="padding: 100px 0;">
            <img src="/static/images/res/noSup.png" class="noSup">
            <div class="dl-info">{{ errorTip }}</div>
        </div>
        <!-- <div class='mask' v-if='isNeedMask'></div> -->
    </div>
</div>
</template>

<script>
// wyp 引入视频模块 2021/10/13
import videoTemplete from '@/components/res/LectureResources/videoTemplete.vue'

export default {
    props:{
        info:{
            type:Object,
            default: null
        },
        onResDownload: {
            type: Function,
            default: null
        }
    },
    components: {
        videoTemplete,
    },
    data () {
        return {
            isPhone: false,
            isLhPhone: false,
            loading2: true,
            iframeUrl:null,
            imgUrl:null,
            videoUrl:null,
            audioUrl:null,
            noSupport:false,
            fixedHeightStatus:false,
            previewYongZhongBase : 'https://office.yueedu.com/op/view.aspx?src=',
            previewKanShanBase: 'https://fordata.forclass.net/Program/Test/WS/KSPlayer/index.html?url=',
            imgArr:['jpg',"jpeg", 'jpeg', 'gif', 'png', 'bmp', 'tif'],
            audioArr:['mp3', 'm4a', 'mid', 'wma','wav', 'ogg'],
            videoArr:["mp4",'yoya','flv'],
            docArr:['doc','txt','docx','pdf','wps','odt','rtf','xls','xlsx','et','ods','csv','ppt','pptx','dps','odp'],
            noUrl: false,
            yoyaVideo:null,
            movieConfig:{
                token: null,
                appkey: null,
                grant_code: null
            },
            resExt: null,
            expires_time: 2 * 60 * 60 * 1000,
            iframeLoaded: false,
            isLogin: this.isValid(this.loadAccount()) && this.loadAccount() != '',
            errorTip: '未找到相应资源',
            isAndroid: this.getSessionVal('device') == 'androidPadCKL' || this.getCookie('device') == 'androidPadCKL',
            playerOptions:{
                playbackRates: [2.5, 2.0, 1.5, 1.0,0.75,0.5], //可选择的播放速度
                autoplay: true, //如果true,浏览器准备好时开始自动播放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 视频一结束就重新开始。
                src: null, //url地址
                timeDivider: ":", //当前时间和持续时间的分隔符样式
                barLeft: "3em", //底部控制条栏目距左侧距离
            },//wyp 添加视频参数 2021/10/13
            deviceIsPc:false,
        }
    },
    computed:{
        // isNeedMask(){
        //     let flag = false
        //     if(this.isLogin){
        //         let role = this.loadAccount().role
        //         let exts = ['doc','docx','pdf']
        //         if(role == '学生'){
        //             if(this.resExt){
        //                 if($.inArray(this.resExt,exts) >= 0){
        //                     flag = true
        //                 }
        //             }
        //         }
        //     }
        //     return flag
        // },
        isPhone(){
            let adapter = this.getSessionVal('adapter');
            return this.isValid(adapter) && adapter != '' && adapter == 'sunphone'
        },
        is271() {
            let is271 = false
            let Platform = this.$store.state.platform
            if(this.isValid(Platform)) {
                let platformNumber = Platform.PlatformSystemNumber
                is271 = platformNumber == '000011'
            }
            return is271
        }
    },
    methods:{
        deviceIsPc1(){
            // wyp 判断设备 2021/11/1
            let device = this.getSessionVal('device');
            let device1=(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
            // if(device == 'androidPadCKL'){
            if(device1){
                // 是移动端
                this.deviceIsPc=false
            }else{
                this.deviceIsPc=true
            }
        },
        imgLoadError(){
            this.imgUrl = null
            this.noUrl = true
        },
        getUrlExt(url){
            url.substring(url.lastIndexOf('/') + 1)
            return url.substring(url.lastIndexOf('.') + 1).toLowerCase()
        },
        getResType(resType){
            if(this.imgArr.indexOf(resType) != -1){
                return '图片'
            }else if(this.audioArr.indexOf(resType) != -1){
                return '音频'
            }else if(this.videoArr.indexOf(resType) != -1){
                return '视频'
            }else if(this.docArr.indexOf(resType) != -1){
                return '文档'
            }else if(resType == "vs4"){
                return '看山视频'
            }else{
                return '暂不支持'
            }
        },
        utf8Transform(str){
            return encodeURI(str)
        },
        getDonwloadUrl(callback){
            let url = ''
            if(this.isValid(this.info.SourceNo) && this.info.SourceNo != ''){
                let paramJSON = $.param({
                    'session':this.getSession(),
                    'movie_id':this.info.SourceNo
                })
                this.startLoading()
                let getMovieDownloadPathURL = this.getYoYaURL('GetYYMovieById?'+paramJSON)
                this.$http.get(getMovieDownloadPathURL).then((result)=>{
                    this.stopLoading()
                    if(this.isValid(result.data) && result.status > 0){
                        url = result.data.data.downloadPath
                        if(this.isValid(callback) && typeof callback == 'function'){
                            callback(url)
                        }
                    }
                })
            } else{
                url = this.getAttachFileUrl(this.info.Url)
                if(this.isValid(callback) && typeof callback == 'function'){
                    callback(url)
                }
            }
        },
        addAttachDownload(){
            let param = {
                session: this.getSession(),
                page: 1,
                paperIdx: this.info.Idx
            }
            this.$http.post(this.getUrl('AddAttachDownload'), this.$qs.stringify(param)).then(() => {})
        },
        getUrl(url) {
            return this.getWSDomain() + this.getWebServiceURL4(url)
        },
        getDownload(){
            if(typeof this.onResDownload == 'function'){
                this.onResDownload(this.info)
            }else{
                this.getDonwloadUrl((url)=>{
                    this.addAttachDownload()
                    let device = this.getSessionVal('device')
                    let resResource = this.info.RSource || this.info.ResourceSource
                    if(this.isValid(device) && device == 'forclasswin'){ //客户端环境
                        let type = null
                        let unitidx = 0
                        let className = null
                        let name = this.info.Name
                        let source = this.info.ShareMode || '资源下载'
                        let size = this.info.Size
                        if(this.isValid(resResource)){
                            unitidx = resResource[0].UIdx
                            className = resResource[0].UName2
                        }
                        let arr = [{
                            'name': name,
                            'url': url,
                            'source': source,
                            'size': size,
                            'dataid': this.info.Idx
                        }]
                        this.sendCPPMessage(arr, type, className)
                    }else if(this.isValid(device) && device == 'androidPadCKL'){
                        let subject = this.getLocalVal('ssubject') || '其它'
                        if(this.isValid(resResource)){
                            subject = resResource[0].SubjectName
                        }
                        let docuName = this.info.Name
                        let resUrl = this.info.Url
                        let ext = ''
                        if(this.isValid(resUrl) && resUrl != ''){
                            ext = resUrl.substring(resUrl.lastIndexOf('.') + 1).toLowerCase();
                            if(docuName.indexOf(ext) < 0){
                                docuName += '.'+ ext
                            }
                        }
                        // 针对优芽做的处理，和 移动端 方面约定
                        // 优芽视频地址拼接后面加&+科目|文件名
                        // 其它地址 拼接后面加?+科目|文件名
                        if(this.isValid(this.info.SourceNo)){
                            url = url + '&'+ subject + '|' + docuName
                        }else{
                            url = url + '?'+ subject + '|' + docuName
                        }
                        window.open(url, '_blank')
                    }else{
                        location.href = url
                    }
                })
            }
        },
        setTeachingResources(){ // 教学资源，教研资源预览
            let handledUrl = this.getAttachFileUrl(this.info.Url)
            let reg = new RegExp("^(http|https|HTTP|HTTPS)\://")
            if(this.info.Url && reg.test(handledUrl)){
                this.noUrl = false
            }else if(!this.isValid(this.info.SourceNo) || this.info.SourceNo == ""){
                this.noUrl = true
            }
            let ext = this.getUrlExt(handledUrl)
            this.resExt = ext
            let resType = this.getResType(ext)
            if(ext == 'yoya' || (this.isValid(this.info.SourceNo) && this.info.SourceNo != "")){ // 获取token和clientid
                let session = this.getSession()
                let grantParam = {
                    session: this.getSession(),
                    movie_id:this.info.SourceNo
                }
                let getYYGrantPathURL = this.getYoYaURL('GetYYGrant?'+$.param(grantParam))
                let getYYAppKeyPathURL = this.getYoYaURL('GetYYAppKey?session='+session)
                this.$http.get(getYYGrantPathURL).then((result)=>{
                    this.movieConfig.grant_code = result.data.data
                    this.$http.get(getYYAppKeyPathURL).then((resultAppkey)=>{
                        this.movieConfig.appkey = resultAppkey.data.data.appkey
                        let config = {
                            clientid: this.movieConfig.appkey,
                            movie_id: this.info.SourceNo,
                            token: "",
                            grant_code:this.movieConfig.grant_code,
                            userid: "",
                            movieData:"",
                            movietop: "",
                            didianID: '',
                            actionID: '',
                            preview: '',
                            playstate: '',
                            controls: ["play", "pause", "replay", "previous", "next", "volume", "drawingboard", 'caption', "fullscreen", "time"],
                            hideControl: '',
                            defCssMp: '',
                            defCssPc: ''
                        }
                        this.loading2 = false
                        this.yoyaVideo = 'yoya'
                        let VM = this
                        let player = OneMoviPlayer('result-box','res-yoya-player')
                        player.init(config,function(){
                            console.log('播放器成功创建!!!')
                        },function(e){
                            console.log(e)
                            console.log('播放器创建失败!')
                            VM.yoyaVideo = null
                            VM.noUrl = true
                        })
                    })
                })
            }else{
                switch(resType){
                    case '图片':
                        this.imgUrl = handledUrl
                        this.loading2 = false
                    break
                    case '音频':
                        this.audioUrl = handledUrl
                        this.loading2 = false
                    break
                    case '视频':
                        this.videoUrl = handledUrl
                        // wyp 设置视频路径参数 2021/10/13
                        this.playerOptions.src =handledUrl
                        this.loading2 = false
                        // if(!this.isAndroid){
                        //     var $flowplayer = $('<script>',{'src':'/static/flowplayer/flowplayer-3.2.12.min.js','type':'text/javascript'})
                        //     this.appendScriptToBody('flowplayer', $flowplayer)
                        //     this.$nextTick(()=>{
                        //         this.playVideo()
                        //     })
                        // }
                    break
                    case '看山视频':
                        this.iframeUrl = this.previewKanShanBase + handledUrl;
                    break
                    case '文档':
                        let tempUrl = this.utf8Transform(handledUrl)
                        tempUrl = tempUrl.replace('forcass-res.oss-cn-qingdao.aliyuncs.com','fcdata.forclass.net')

                        let adapter = this.getSessionVal('adapter');
                        let suffix = tempUrl.slice(tempUrl.lastIndexOf('.') + 1).toLocaleLowerCase();
                        // excel/pdf 走 onlyOffice
                        if (suffix == 'pdf' || suffix == "xls" || suffix == "xlsx") {
                            this.onlyOfficeShowFile(this.info).then(res => {
                                if(res) this.iframeUrl = res.data;
                            });
                            return;
                        }
                        if(this.isValid(adapter) && adapter != '' && adapter == 'sunphone' && suffix == 'pdf'){
                            this.iframeUrl = this.getWSDomain() + '/pdfjs/web/viewer.html?file='+tempUrl
                        }else{
                            this.iframeUrl = this.previewYongZhongBase + tempUrl // 拼接预览的地址
                        }
                    break
                    case '暂不支持':
                        this.noSupport = true
                        this.loading2 = false
                    break
                }
            }
            this.$nextTick(()=>{
                let that = this
                if(this.imgUrl != null){
                    let imgDom = $(this.$el).find('img');
                    imgDom.load(function(){
                        let src = $(this).attr('src')
                        if(src.indexOf('/static/img/noSup') != -1){
                            that.imgUrl = null
                            that.noUrl = true
                        }
                    })
                }
            })
        },
        playVideo() {
            let that = this
            // 获取并查看flowplayer实例 flowplayer(0)
            flowplayer("flash-player", "/static/flowplayer/flowplayer-3.2.16.swf",{
                clip:{
                    autoPlay:false,//是否自动播放，默认true
                    autoBuffering:true,//是否自动缓冲视频，默认true
                    scaling:'scale',//缩放比例 'orig' 'fit' 'scale'
                    urlEncoding:true,//编码支持，比如:中文
                },
                plugins: {
                    controls: {
                        bottom: 0,//功能条距底部的距离
                        height: 30, //功能条高度
                        zIndex: 1,
                        playlist: false,//上一个、下一个按钮
                        play:true, //开始按钮
                        stop: true,//停止按钮
                        volume: true, //音量按钮
                        mute: true, //静音按钮
                        fullscreen: true, //全屏按钮
                        scrubber: true,//进度条
                        url: "/static/flowplayer/flowplayer.controls-3.2.15.swf",
                        time: true, //是否显示时间信息
                        opacity: 0.8, //功能条的透明度
                        tooltips: {
                            buttons: true,//是否显示
                            fullscreen: '全屏',//全屏按钮，鼠标指上时显示的文本
                            stop:'停止',
                            play:'开始',
                            pause:'暂停',
                            volume:'音量',
                            mute: '静音',
                            unmute: '取消静音',
                            next:'下一个',
                            previous:'上一个'
                        }
                    }
                },
                onStart: function(a, b, c){
                },
                onFullscreen : function() {
                },
                onFinish: function(){
                    // 播放完重新播放
                    this.play();
                },
                onError: function(statusCode, statusInfo){
                    console.log(statusCode, statusInfo)
                    that.videoUrl = null
                    that.noSupport = true
                }
            });
        },
        setDigitalTestPaper(){ // 数字试卷预览
            if(this.isValid(this.info.Summary)){
                let paperInfo = this.info.Summary.id
                let param = {
                    "module": "papermanual",
                    "papersource": this.info.Summary.paperSource,
                    "paperid": paperInfo.id,
                    "contenttype": 1,
                    "paperversion": paperInfo.version,
                    "hidenav": true
                }
                this.iframeUrl = this.getZznScJumpUrl(param)
            }else{
                this.loading2 = false
                this.noSupport = true
            }
            
        },
        clearPreview(){
            this.iframeLoaded = false
            this.imgUrl = null
            this.iframeUrl = null
            this.videoUrl = null
            this.audioUrl = null
            this.noSupport = false
            this.loading2 = false
            this.yoyaVideo = null
            this.loading2 = true
        },
        updateReview() {
            let item = this.info
            if(this.is271){
                if(this.isValid(this.info.Idx) && this.isValid(this.info.TypeIdx)){
                    let user_id = this.loadAccount().userid
                    let local_key = item.Idx
                    let forbidden_list = 'forbidden_' + user_id
                    let localVal = this.getLocalVal(forbidden_list)
                    let now_time = new Date().getTime()
                    if(this.isValid(localVal) && localVal != ''){
                        localVal = JSON.parse(localVal)
                        if(this.isValidArray(localVal)){
                            let flag = false
                            for(let i=0;i<localVal.length;i++){
                                let temp_item = localVal[i]
                                let storageIdx = temp_item.idx
                                let expires = temp_item.expires
                                if(storageIdx == local_key){
                                    flag = true
                                    if(now_time >= expires){
                                        this.addResLook()
                                        temp_item.expires = now_time + this.expires_time
                                    }
                                }
                            }
                            if(!flag){
                                this.addResLook()
                                localVal.push({
                                    idx: item.Idx,
                                    expires: now_time + this.expires_time
                                })
                            }
                        }
                    }else{
                        // 2 小时过期
                        localVal = [{
                            idx: item.Idx,
                            expires: now_time + this.expires_time
                        }]
                        this.addResLook()
                    }
                    this.updateLocal(forbidden_list,JSON.stringify(localVal))
                }
            }else{
                if(this.isValid(this.info.Idx) && this.isValid(this.info.TypeIdx)){
                    this.addResLook()
                }
            }
        },
        addResLook(){
            return
            if(!this.info.Not_Add_Resource_Look_Insert){
                let api = this.getWSDomain() + this.getWebServiceURL4('Resource_Look_Insert')
                let param = {
                    session: this.getSession(),
                    ptIdx: this.info.TypeIdx,
                    rIdx: this.info.Idx,
                    page: 1
                }
                this.addToQueue(api,param,(res)=>{
                    if(res.ReturnCode > 0){
                        this.info.BrowseNum += 1
                    }
                },null,true)
            }
        },
        previewRes(){ // 这里只处理同步应用
            if(this.$route.path.toLowerCase() == '/console/resaudit'){ // 审核页面
                this.fixedHeightStatus = true
            }else{
                this.fixedHeightStatus = false
                this.updateReview()
            }
            if(this.info != null){
                this.clearPreview()
                if(this.info.TypeIdx == 3){
                    this.setTeachingResources()
                }else if(this.info.TypeIdx == 2){
                    this.setDigitalTestPaper()
                }
            }
            if(this.iframeUrl != null){
                this.$nextTick(()=>{
                    let iframe1 = $(this.$el).find('#frame1')
                    iframe1.load(()=>{
                        this.loading2 = false
                    })
                })
            }
        },
        iframeLoad(){
            this.loading2 = false
            if(this.isLogin && this.loadAccount().role == '学生' && this.is271){
                if(this.iframeLoaded == true){
                    this.iframeLoaded = false
                    // let frameUrl = this.iframeUrl
                    // this.iframeUrl = null
                    this.$nextTick(()=>{
                        // this.iframeUrl = frameUrl
                        this.$emit('errorLink')
                    })
                }else{
                    this.iframeLoaded = true
                }
            }
        },
        getSunResType(info){
            if(info.Url){
                let type = info.Url.split('.')
                type = type[type.length-1].toLowerCase()
                if(type == 'ppt'||type == 'doc'||type == 'pdf'||type == 'docx'){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        },
    },
    mounted(){
        this.$nextTick(() => {
            let adapter = this.getSessionVal('adapter')
            this.isLhPhone = (this.isValid(adapter) && adapter != '' && adapter == 'lhphone') ? true : false
        })
        var $movieplayer = $('<script>',{'src':'https://api.onemovi.com/GetPlayerScript?client_id=pro&version=1.0&ts=20180927175235','type':'text/javascript'})
        this.appendScriptToBody('movieplayer', $movieplayer)
    },
    watch:{
        info(){
            this.previewRes()
        }
    },
    created(){
        if(this.isAndroid){
            this.videoArr = ["mp4",'yoya']
        }
        this.previewRes()
        this.deviceIsPc1()
    }
}
</script>
<style scoped>
/* wyp 2021/9/16 去除视频黑框问题 */
#previewVideo{
    outline: none;
    border: none;
}
.res-box{
    width: 100%;
    height: 90vh;
    display: table;
    text-align: center;
}
.phone-res-box-wrapper{
    height: 90% !important;
    overflow: auto;
}
.phone-res-box{
    height: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.result-box{
    /* height: 600px; */
    min-height: 90vh;
    display: table-cell;
    vertical-align: middle;
    position: relative;
}
.preview-video{
    height: calc(100vh - 120px);
}
.fixedHeight{
    height:100%;
}
.relativeHeight{
    height:90vh;
}
.noSup {
    display: block;
    margin: 0 auto 30px;
    width: 200px;
    align-items:center;
}
.no-support{
    width: 100%;
    padding: 150px 0;
}
.dl-info {
    text-align: center;
    font-size: 18px;
}
.dl-iflink, .dl-iflink:visited {
    color: #f98303;
}
.previewImg{
    max-height:90vh;
    max-width:100%;
    text-align:center;
    margin: 0 auto;
    margin-bottom: 100px;
}
.res-box audio{
    width: 80%;
    margin: 0 auto;
}
.demo-spin-col{
    height: 90vh;
    width: 100%;
    position: relative;
    border: 1px solid #eee;
}
#result-box >>> .res-yoya-player{
    width: 100%;
    min-height: 600px;
}
.mask{
    width: 90%;
    height: 110%;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    top: -5%;
}
#flash-player{
    width: 100%;
    height: 100%;
}
.phone-video{
    display: flex;
    align-items: center;
    justify-content: center;
}
.res-box >>> audio:focus{
    outline: none;
}
</style>
