<template>
  <div id="input_video">
    <div id="video-player" ref="videoplayer">
      <video
        id="videoDiv"
        ref="myvideo"
        @canplay="getTotal"
        @timeupdate="timeupdate"
        x-webkit-airplay="allow"
        playsinline="playsinline"
        webkit-playsinline="true"
        x5-playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="false"
        preload="auto"
        @click="hideBarEvent"
        controlsList="nodownload"
        :loop="playerOptions.loop"
        :controls="false"
        :muted="isMuted"
      >
        <source :src="playerOptions.src" />
      </video>
      <!-- <div class="big-play-button" v-show="!isPaused" @click="togglePlay">
        暂停
      </div> -->
      <div
        class="control-bar"
        v-show="isShowControlBar"
        :style="{
          left: isFullscreen ? playerOptions.barLeft : 0,
        }"
      >
        <div class="play-control">
          <img
            :src="isPaused ? imgUrl.src1 : imgUrl.src2"
            alt=""
            @click="togglePlay()"
          />
        </div>
        <div class="timeShow">
          <span>{{ currentTime }}</span>
        </div>
        <div class="progress-control" :style="{ width: 'calc(100% - 28em)' }">
          <div class="controlDiv" @touchstart="timePause" @click="timePlay">
            <el-slider
              :show-tooltip="false"
              v-model="proCurrentTime"
              :max="parseInt(proTotalTime)"
              @change="changeVideoTime"
            >
            </el-slider>
          </div>
        </div>
        <div class="timeShow">
          <span>{{ totalTime }}</span>
        </div>
        <div class="volume-panel">
          <div @click="showVolumeProgress" class="volume-panel-bar">
            <div
              class="barImg"
              :style="{ backgroundPositionX: isMuted ? '100%' : '0' }"
            ></div>
          </div>
          <div class="volumeProgress" v-show="isShowVolume">
            <!-- <span @click="changeMuted">{{ isMuted ? "静音" : "声音" }}</span> -->
            <div @mousemove="mouseup">
              <el-slider
                :show-tooltip="false"
                v-model="volumeNum"
                :max="100"
                @change="changeVolumeNum"
                vertical
                height="6em"
              >
              </el-slider>
            </div>
          </div>
        </div>
        <div class="speed-control">
          <div @click="showPlayRates" class="speed-panel-bar">
            <div>{{ curentPlayRates }}×</div>
          </div>
          <div class="playRates" v-show="isShowPlayRates">
            <div
              v-for="(item, i) in playerOptions.playbackRates"
              :key="i"
              @click="changePlayRates(item)"
              :style="{
                color: item == curentPlayRates ? '#3CE0D8' : '#FFFFFF',
              }"
            >
              {{ item }}×
            </div>
          </div>
        </div>
        <div class="fullscreen-control">
          <div @click="toggleFullScreen">
            {{ isFullscreen ? "退出全屏" : "全屏" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playerOption: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始自动播放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        src: null, //url地址
        timeDivider: null, //当前时间和持续时间的分隔符样式
        barLeft: null, //距左侧距离
      },
      imgUrl: {
        src1: require("../../../assets/images/video/pause.png"),
        src2: require("../../../assets/images/video/play.png"),
      },
      imgBgStyle: {
        style1: {},
        style1: {},
      },
      myvideo: null, //video
      isPaused: false, //默认播放
      currentTime: "00:00:00", //当前时间
      totalTime: "00:00:00", //总播放时间
      proCurrentTime: 0, //进度条的值
      proTotalTime: 0, //进度条的值
      curentPlayRates: 1.0, //当前播放速度
      volumeNum: 50, //声音大小
      isShowVolume: false, //是否展示声音弹框
      isMuted: false, //是否静音
      isFullscreen: false, //是否全屏
      isShowControlBar: true, //是否展示控制条
      setTimes: null, //设置延迟后控制条消失
      isShowPlayRates: false, //是否展示倍速弹框
      isDrawing: false, //是否拖动
      drawSettime: null, //拖动时候的定时器
      isLoad: true, //是否初次加载
      btnPlay: false, //拖动前是否播放状态
    };
  },
  computed: {},
  methods: {
    //播放与暂停
    togglePlay() {
      //修改当前的播放状态
      this.isPaused = !this.isPaused;
      if (this.isPaused) {
        // 播放完成重新播放
        this.myvideo.play();
      } else {
        this.myvideo.pause();
      }
      this.isShowVolume = false;
      this.hideEvent();
      this.mouseup();
    },
    //停止播放
    stopPlay() {
      //没有提供原生的stop方法
      this.myvideo.pause();
      this.myvideo.currentTime = 0;
      //重置播放状态为初始值
      this.isPaused = false;
      this.mouseup();
    },
    //时间格式化处理
    timeFormat(time) {
      // time 为秒
      let hour = Math.floor(time / (60 * 60)); //1小时3600秒
      let minute = Math.floor((time / 60) % 60); //分
      let second = Math.floor(time % 60);
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return (
        hour +
        this.playerOptions.timeDivider +
        minute +
        this.playerOptions.timeDivider +
        second
      );
    },
    //获取总时长
    getTotal() {
      // this.proTotalTime = this.myvideo.duration;
      this.proTotalTime = 100;
      this.totalTime = this.timeFormat(this.myvideo.duration);
    },
    //获取当前视频播放到的时间
    timeupdate() {
      this.proCurrentTime = parseFloat(
        (this.myvideo.currentTime / this.myvideo.duration) * 100
      );
      this.currentTime = this.timeFormat(this.myvideo.currentTime);
      // 播放完暂停
      if (this.proCurrentTime >= this.proTotalTime) {
        this.myvideo.pause();
        this.isPaused = false;
      }
    },
    //静音操作
    toggleMuted() {
      //修改图标
      this.isMuted = !this.isMuted;
      //静音
      this.myvideo.muted = !this.myvideo.muted;
      this.mouseup();
    },
    //全屏切换
    toggleFullScreen(event) {
      this.hideEvent();
      //如果当前是全屏状态，就退出全屏，否则进入全屏状态
      //获取当前的全屏状态
      let myvideos = this.$refs.videoplayer;
      if (this.isFullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (myvideos.requestFullscreen) {
          myvideos.requestFullscreen();
        } else if (myvideos.webkitRequestFullScreen) {
          myvideos.webkitRequestFullScreen();
        } else if (myvideos.mozRequestFullScreen) {
          myvideos.mozRequestFullScreen();
        } else if (myvideos.msRequestFullscreen) {
          myvideos.msRequestFullscreen();
        }
      }
      this.mouseup();
    },
    changeMuted() {
      this.isMuted = !this.isMuted;
      this.mouseup();
    },
    changeVideoTime() {
      this.myvideo.currentTime =
        (this.proCurrentTime / 100) * this.myvideo.duration;
      this.timeupdate();
      this.hideEvent();
      this.mouseup();
    },
    timePause() {
      //拖动 暂停视频
      if (this.isPaused) {
        // 拖动前为播放状态
        this.btnPlay = true;
      } else {
        this.btnPlay = false;
      }
      this.isDrawing = true;
      this.myvideo.pause();
      this.isPaused = false;
      this.drawSettime = null;
      this.hideEvent();
      this.mouseup();
    },
    timePlay() {
      // 上次点击
      // 如果拖动前为播放状态，则设置时间后继续播放
      if (this.btnPlay) {
        this.drawSettime = setTimeout(() => {
          this.myvideo.play();
          this.isPaused = true;
          this.isDrawing = false;
          this.drawSettime = null;
        }, 300);
      }
    },
    // 展示播放速度框
    showPlayRates() {
      this.isShowPlayRates = !this.isShowPlayRates;
      this.isShowVolume = false;
      this.mouseup();
    },
    changePlayRates(val) {
      // 播放速率
      this.isShowVolume = false;
      this.curentPlayRates = val;
      this.myvideo.playbackRate = this.curentPlayRates;
      this.showPlayRates();
      this.mouseup();
    },
    showVolumeProgress() {
      // 展示声音滑块
      this.isShowVolume = !this.isShowVolume;
      this.isShowPlayRates = false;
      this.mouseup();
    },
    changeDown() {
      this.myvideo.pause();
      this.isPaused = false;
    },
    changeUp() {
      this.myvideo.play();
      this.isPaused = true;
    },
    changeVolumeNum() {
      // 声音大小
      let volumeNum = parseFloat(this.volumeNum / 100.0);
      this.myvideo.volume = volumeNum;
      if (volumeNum <= 0) {
        this.isMuted = true;
      } else {
        this.isMuted = false;
      }
    },
    mouseup() {
      // 鼠标抬起事件
      this.isShowControlBar = true;
      clearTimeout(this.setTimes);
      this.setTimes = null;
      this.setTimes = setTimeout(() => {
        if (this.isShowControlBar) {
          this.isShowControlBar = false;
        }
        this.setTimes = null;
      }, 6000);
    },
    hideBarEvent() {
      this.hideEvent();
      this.mouseup();
    },
    hideEvent() {
      this.isShowVolume = false;
      this.isShowPlayRates = false;
    },
  },
  mounted() {
    //获取播放器元素
    this.myvideo = this.$refs.myvideo;
    // 监听全屏事件
    let that = this;
    document.addEventListener("fullscreenchange", () => {
      that.isFullscreen = !that.isFullscreen;
    });
    document.addEventListener("mozfullscreenchange", () => {
      that.isFullscreen = !that.isFullscreen;
    });
    document.addEventListener("webkitfullscreenchange", () => {
      that.isFullscreen = !that.isFullscreen;
    });
    document.addEventListener("msfullscreenchange", () => {
      that.isFullscreen = !that.isFullscreen;
    });
    // 监听video加载完成
    this.myvideo.addEventListener("canplaythrough", function () {
      if (that.playerOptions.autoplay) {
        // 自动播放默认暂停
        if (that.isLoad) {
          that.isLoad = false;
          that.togglePlay();
        }
      }
    });
  },
  watch: {
    playerOption: {
      handler: function (val) {
        if (val && val.constructor === Object) {
          let that = this;
          Object.keys(val).forEach(function (key) {
            that.playerOptions[key] = val[key];
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
};
</script>
<style scoped>
#input_video {
  width: 100%;
  height: 90%;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
}

#input_video #video-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#input_video #video-player #videoDiv {
  height: calc(100vh - 120px);
  width: 100%;
  outline: none;
  border: none;
}

#input_video .big-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 2em;
  font-size: 3.5em;
  width: 3em;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 0.3em;
  cursor: pointer;
  text-align: center;
  line-height: 2em;
}

#input_video .big-play-button:hover {
  background-color: rgba(36, 131, 213, 0.9);
}

#input_video .control-bar {
  display: flex;
  width: 95%;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.5em;
  font-size: 14px;
  background-color: rgba(43, 51, 63, 0.7);
  justify-content: space-around;
}

#input_video .control-bar .play-control {
  position: relative;
  width: 3em;
  text-align: center;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
#input_video .control-bar .play-control img {
  width: 60%;
}
#input_video .control-bar .volume-panel {
  position: relative;
  width: 3em;
  text-align: center;
  height: 100%;
  cursor: pointer;
  color: rgba(255, 255, 255);
}

#input_video .control-bar .volume-panel .volumeProgress {
  position: absolute;
  height: 8em;
  padding-top: 1em;
  padding-bottom: 1em;
  bottom: 4em;
  background: rgba(43, 51, 63, 0.7);
}

#input_video .control-bar .volume-panel .volume-panel-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#input_video .control-bar .volume-panel .volume-panel-bar .barImg {
  height: 70%;
  width: 70%;
  background: url(../../../assets/images/video/voice.png) no-repeat center;
  background-position: 100% 0.2em;
  background-size: 200%;
}

#input_video .control-bar .timeShow {
  position: relative;
  text-align: center;
  height: 100%;
  font-size: 1em;
  line-height: 3em;
  color: rgba(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}

#input_video .control-bar .progress-control {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#input_video .control-bar .progress-control progress {
  margin: 0 10px;
  width: calc(100% - 20px);
  height: 1em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#input_video .control-bar .progress-control .controlDiv {
  width: 100%;
}
#input_video .control-bar .progress-control .progressBar {
  position: absolute;
  top: 0;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255);
  cursor: pointer;
}

#input_video .control-bar .speed-control {
  position: relative;
  width: 3em;
  height: 100%;
  cursor: pointer;
  color: rgba(255, 255, 255);
}
#input_video .control-bar .speed-panel-bar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#input_video .control-bar .fullscreen-control {
  position: relative;
  text-align: center;
  width: 5em;
  height: 100%;
  cursor: pointer;
  color: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
}

#input_video .control-bar .speed-control .el-select .el-input__inner {
  padding: 0;
  text-align: center;
  background: none;
  color: rgba(255, 255, 255);
  border: none;
}

#input_video .control-bar .speed-control /deep/ .el-input__inner {
  padding: 0;
  text-align: center;
  background: none;
  color: rgba(255, 255, 255);
  border: none;
}
#input_video .control-bar .playRates {
  position: absolute;
  bottom: 4em;
  background-color: rgba(43, 51, 63, 0.7);
  padding: 1em;
  line-height: 2em;
}
#input_video .control-bar .speed-control .el-input__suffix {
  display: none;
}

#input_video .control-bar /deep/ .el-input__suffix {
  display: none;
}
#input_video .control-bar /deep/ .el-slider__bar {
  background-color: #26bcb4;
}
#input_video .control-bar /deep/ .el-slider__button {
  border: 2px solid #26bcb4;
  width: 50%;
  height: 50%;
}
video::-webkit-media-controls-fullscreen-button,
video::-moz-media-controls-fullscreen-button,
video::-ms-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-play-button,
video::-moz-media-controls-play-button,
video::-ms-media-controls-play-button {
  display: none;
}

video::-webkit-media-controls-timeline,
video::-moz-media-controls-timeline,
video::-ms-media-controls-timeline {
  display: none;
}

video::-webkit-media-controls-current-time-display,
video::-moz-media-controls-current-time-display,
video::-ms-media-controls-current-time-display {
  display: none;
}

video::-webkit-media-controls-time-remaining-display,
video::-moz-media-controls-time-remaining-display,
video::-ms-media-controls-time-remaining-display {
  display: none;
}

video::-webkit-media-controls-mute-button,
video::-moz-media-controls-mute-button,
video::-ms-media-controls-mute-button {
  display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button,
video::-moz-media-controls-toggle-closed-captions-button,
video::-ms-media-controls-toggle-closed-captions-button {
  display: none;
}

video::-webkit-media-controls-volume-slider,
video::-moz-media-controls-volume-slider,
video::-ms-media-controls-volume-slider {
  display: none;
}
</style>
