<!--
 * @Author: your name
 * @Date: 2021-04-08 09:24:00
 * @LastEditTime: 2021-06-22 13:39:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\Common\ResPreview\NewPreview.vue
-->
<template>
  <div class='NewPreview'>
      <div class="header">
          <span class="name">{{details.Name}}</span>
          <span class="close-icon" @click="onClickClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="preview-content">
          <res-preview v-if="showPreview" :info="details"></res-preview>
      </div>
  </div>
</template>
<script>
import resPreview from '@/components/res/LectureResources/ResPreview.vue'
export default {
  name: 'NewPreview',
  props:['details'],
  components: {resPreview},
  data () {
    return {
        showPreview:false
    }
  },
  computed: {},
  watch: {
      details:{
          handler:function(nVal){
              if(nVal){
                  this.showPreview = true
              }
          },
          immediate:true
      }
  },
  created () {
  },
  mounted () {
  },
  methods: {
      onClickClose(){
          this.$emit('close')
      }
  }
}
</script>

<style lang='less' scoped>
    .NewPreview{
        width: 80vw;
        min-height: 100vh;
        background: #FFF;
        z-index: 999;
        position: absolute;
        top: 0;
        margin: 0 auto;
        left: 0;
        right: 0;
        padding:20px ;
        .name{
            font-size: 20px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 20px;
        }
        .header{
            display: flex;
            justify-content: center;
            // margin-bottom: 30px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            position: relative;
            .close-icon{
                position:absolute;
                right: 0;
                top: 0px;
                font-size: 20px;
                display: inline-block;
                height: 30px;
                width: 30px;
                cursor: pointer;
            }
        }
        .preview-content{
            height: calc(100% - 50px);
        }
    }

</style>
